.call {
  position: absolute;
  position:fixed;
  bottom:60px;
  right:140px;
  background-color:#1c1d1f;
  box-shadow: 0 0 16px #0004;
  color:#fff;
  border-radius: 3rem;
  padding: .2rem 1rem;
  text-align:center;
  font-size:1.4em;
  font-weight: bold;
  z-index:100;
}



.whatsapp {
    position:fixed;
    width:80px;
    height:80px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
  }
  
  .whatsapp-icon {
    margin-top:21px;
  }

  .whatsapp:hover {
    background-color:#00ad40;

  }

  @media (max-width: 850px) {
    .call {
      display: none;
    }
}