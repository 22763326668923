.vender a {
    display: inline-block;
    position: relative;
    border-radius: 3rem;
    bottom: 1.6rem;
    background-color: var(--first-color);
    color: #fff;
    padding: 1rem 1.6rem;
    font-size: large;
    font-weight: var(--font-medium);
    border: none;
    cursor: pointer;
}

.vender a:hover{
    background-color: var(--first-color-lighter);

}



.venderText h2 span {
    color: var(--first-color) !important;
}

.venderText h2 {
    color: var(--second-color);
    text-align: left;
    padding-bottom: 1rem;
}

.venderText p {
    text-align: left;
    font-size: var(--h3-font-size);
    line-height: 2.2rem;

}