.menu {
    background-color: #0003;
    margin-top: 1rem;
    padding: .6rem 0;
    
}
.menu a {
    color: white;
    padding: .8rem 1rem !important;
    margin: 0 3rem;
    letter-spacing: .08rem;
}


.menu a:hover {
    background-color: var(--first-color-lighter);
}

.hero {
    height: 100%;
}

a.active, div.active {
    background-color: var(--first-color-lighter) !important;
    color: white !important;
}


.carousel img {
    filter: brightness(0.6);

}

.hero {
    background: url("../../../public/img/bg2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    padding-top: 12rem;
}

header {
    height: 700px;
}

.heroBox {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
}

.heroBox img {
    width: 500px;
}

.heroBox h2 {
    color: var(--body-color);
    font-size: 2.4em;
    text-shadow: 0 0 8px #000;
}

.scr {
    background-color: #0008;
    margin-top: 0;
    border-radius: 0;
    padding: .5rem 0
}



@media (max-width: 600px) {

    .heroBox img {
        width: 300px;
    }
}
@media (max-width: 991px) {
    .menu a {
        padding: 0 !important;
    }
    .menu a div {
        padding: .8rem 1rem !important;
    }

}