.certificados h2 {
    color: var(--second-color);
    margin-bottom: 3rem;
}

.cer {
    height: 100%;
    box-shadow: 0 0 6px #0003;
    padding: 1rem;
}

.cer h3 {
    font-size: 1.4em;
}

.cer img {
    max-height: 160px;
    margin-bottom: .6rem;
}