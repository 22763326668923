.banner {
    background: url("../../../public/img/banner.webp");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 2rem;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

}



.banner__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    gap: 1rem;
}

.banner__box h3 {
    color: var(--body-color);
    font-size: var(--h1-font-size);
}

.banner__box p {
    color: var(--body-color);
    font-size: var(--h3-font-size);
}