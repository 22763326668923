

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

textarea {
    resize: none;
    height: 200px;
}

form input, form textarea {
    border: none;
    outline: none;
    background-color: var(--input-color);
    padding: .6rem 1.4rem;
    
}

form input[type="submit"] {
    background-color: var(--first-color);
}

.info {
    text-align: left;

}

@media (max-width: 768px) {

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 1rem;
    }
}