.nosotros p {
    font-size: var(--h3-font-size);
    line-height: 2.2rem;
    font-size: 1.4em;
    text-align: justify;
    
}

.nosotros {
    text-align: left;
}

.nosotros h2 {
    color: var(--second-color);
    text-align: left;
    
}

.nosotros h2 span {
    color: var(--first-color);
}

.nosotros img {
    width: 100%;
}

.empr {
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

